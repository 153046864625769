import { AlertSubject, AlertType, alertService } from 'utils/alerts';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { Fragment, ReactNode, useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { useRouter } from 'next/router';
interface AlertProviderProps {
  id: string;
  children: ReactNode;
}
export const AlertProvider = ({ id, children }: AlertProviderProps) => {
  const [alerts, setAlerts] = useState<AlertSubject[]>([]);

  const router = useRouter();
  const addAlert = (alert: AlertSubject) => {
    alert.id = `${alert.id}_${alerts.length + 1}`;
    setAlerts([...alerts, alert]);
  };

  const removeAlert = (id: string) => {
    setAlerts(alerts.filter(x => x.id !== id));
  };

  const delayClose = (id: string) => {
    setTimeout(() => {
      removeAlert(id);
    }, 5000);
  };
  useEffect(() => {
    const subscription = alertService
      .onAlert(id)
      .subscribe((alert: AlertSubject) => {
        addAlert(alert);
        if (alert.autoClose) {
          delayClose(alert.id!);
        }
      });
    const clearAlerts = () => {
      alertService.clear();
    };
    router.events.on('routeChangeStart', clearAlerts);
    return () => {
      // unsubscribe to avoid memory leaks
      subscription.unsubscribe();
      router.events.off('routeChangeStart', clearAlerts);
    };
  }, []);

  const getBackgroundColor = (type: string | undefined) => {
    switch (type) {
      case AlertType.Warning:
        return 'bg-yellow-50';
      case AlertType.Error:
        return 'bg-red-50';
      case AlertType.Success:
        return 'bg-green-50';
      default:
        return 'bg-white';
    }
  };
  const getIconColor = (type: string | undefined) => {
    switch (type) {
      case AlertType.Warning:
        return 'text-yellow-400';
      case AlertType.Error:
        return 'text-red-400';
      case AlertType.Success:
        return 'text-green-400';
      default:
        return 'text-gray-500';
    }
  };
  const getTextColor = (type: string | undefined) => {
    switch (type) {
      case AlertType.Warning:
        return 'text-yellow-700';
      case AlertType.Error:
        return 'text-red-700';
      case AlertType.Success:
        return 'text-green-700';
      default:
        return 'text-gray-700';
    }
  };

  const buildAlert = (alert: AlertSubject) => {
    return (
      <div
        id={alert.id}
        role="alert"
        className={`pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg my-3 ${getBackgroundColor(
          alert?.type
        )} shadow-lg ring-1 ring-black ring-opacity-5`}
      >
        <div className="p-4 -">
          <div className="flex items-start">
            <div className="flex-shrink-0">
              <CheckCircleIcon
                className={`h-6 w-6 ${getIconColor(alert?.type)}}`}
                aria-hidden="true"
              />
            </div>
            <div className="ml-3 w-0 flex-1 pt-0.5">
              <p className={`mt-1 text-sm ${getTextColor(alert?.type)}`}>
                {alert?.message}
              </p>
            </div>
            <div className="ml-4 flex flex-shrink-0">
              <button
                type="button"
                className={`inline-flex rounded-md bg-white ${getIconColor(
                  alert?.type
                )} hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
                onClick={() => {
                  removeAlert(alert.id!);
                }}
              >
                <span className="sr-only">Close</span>
                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="alerts fixed right-0 top-0 w-full max-w-sm  z-[1000]">
        {alerts.map(alert => (
          <Transition
            key={alert.id}
            show={true}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            {buildAlert(alert)}
          </Transition>
        ))}
      </div>
      {children}
    </>
  );
};
